<template>
  <div class="align-middle text-center p-10 w-3/4 font-blog mx-auto">
    <article
      v-for="blogPost in this.blogPublished"
      :key="blogPost.key"
      class="post-entry p-2 w-full mx-auto flex flex-wrap mb-2 divide-y-2"
    >
      <div class="flex w-full items-baseline justify-between p-1">
        <span class="text-4xl text-[#666]">
          {{ blogPost.headline }}
        </span>
        <span class="text-sm text-[#aaa]">
          {{ postDisplayDate(blogPost) }}
        </span>
      </div>

      <div
        class="py-6 text-justify whitespace-pre-wrap text-[#666] font-medium font-xl w-full"
      >
        {{ blogPost.body }}
      </div>
      <span class="ml-auto"> </span>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'blog-view',
  data() {
    return {
      password: null
    }
  },
  computed: {
    ...mapState(['blogPublished']),
    ...mapGetters(['postDisplayDate'])
  },
  methods: {}
}
</script>

<style scoped lang="scss"></style>
