<template>
  <section>
    <form @submit.prevent="login">
      <input
        v-model="username"
        id="username"
        type="text"
        name="username"
        placeholder="username"
        minLength="1"
        class="border px-1 py-1"
        value
      />
      <input
        v-model="password"
        id="userPassword"
        type="password"
        name="userPassword"
        placeholder="password"
        minLength="1"
        class="border ml-2.5 px-1 py-1"
        value
      />
      <button
        type="submit"
        name="button"
        class="bg-gray-200 text-black font-normal py-1 px-2 ml-2 rounded border border-gray-400"
      >
        Login
      </button>

      <p class="text-red-600 mt-4">{{ error }}</p>
    </form>
    <router-link class="block mt-3.5" :to="{ name: 'registration' }">
      Don't have an account? Register.
    </router-link>
  </section>
</template>

<script>
import { signInWithEmailAndPassword } from 'firebase/auth'
import { mapGetters, mapState } from 'vuex'
import NProgress from 'nprogress'

export default {
  name: 'login-view',
  data() {
    return {
      username: '',
      password: '',
      error: null
    }
  },
  computed: {
    ...mapState(['fireBaseAuth']),
    ...mapGetters(['getFirebaseEmail', 'isLoggedIn'])
  },
  methods: {
    async login() {
      NProgress.start()
      let unsubscribe = function () {}
      try {
        unsubscribe = this.fireBaseAuth.onAuthStateChanged(
          async (user) => {
            if (user) {
              unsubscribe()
              // User is signed in.
              await this.$store.dispatch('login', {
                username: this.username,
                password: this.password
              })
              NProgress.done()
              await this.$router.push({ name: 'posts' })
            } else {
              // No user is signed in.
            }
          },
          (error) => {
            this.$log.error(error)
            NProgress.done()
          }
        )
        await signInWithEmailAndPassword(
          this.fireBaseAuth,
          this.getFirebaseEmail(this.username),
          this.password
        )
      } catch (err) {
        unsubscribe()
        this.error = err
        NProgress.done()
        this.$toasted.error(err, {
          duration: 2000,
          icon: 'error_outline',
          position: 'bottom-right'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
