import _ from 'lodash'
import moment from 'moment'
import postRobot from 'post-robot'

import Vue from 'vue'
import Vuex from 'vuex'

import completeStory from '../data/story.js'
import ads from '../data/ads.js'
import rcg from '../data/rcg.js'
import blogPosts from '../data/120DaysBlog'

import { urlToName, rcgLink } from '@/store/const.js'
import {
  browserLocalPersistence,
  getAuth,
  signInWithEmailAndPassword
} from 'firebase/auth'
import {
  collection,
  getDoc,
  updateDoc,
  doc,
  onSnapshot
} from 'firebase/firestore'

Vue.use(Vuex)

const readerStartDefault = moment.utc().startOf('day').unix()

export default new Vuex.Store({
  state: {
    complete: JSON.parse(atob(completeStory)),
    siteFiltered: [],
    epochFiltered: [],
    branchFiltered: [],
    replacedFiltered: [],
    tags: {},
    blogName: urlToName[process.env.VUE_APP_MODE][window.location.origin],
    readerStart: readerStartDefault,
    offset: 0,
    username: null,
    password: null,
    setTime: null,
    activeConnectors: 0,
    loginSynced: false,
    loading: false,
    ads: JSON.parse(atob(ads)),
    postsPerPage: 4,
    fireBaseAuth: null,
    fireStore: null,
    replacements: {},
    unsubUserData: null,
    branch: 'T',
    rcg: JSON.parse(atob(rcg)),
    blogPosts: JSON.parse(atob(blogPosts)),
    blogPublished: []
  },
  mutations: {
    ADD_SITE_FILTERED(state, siteFiltered) {
      state.siteFiltered = siteFiltered
    },
    ADD_EPOCH_FILTERED(state, epochFiltered) {
      state.epochFiltered = epochFiltered
    },
    ADD_BLOG_PUBLISHED(state, published) {
      state.blogPublished = published
    },
    ADD_BRANCH_FILTERED(state, branchFiltered) {
      state.branchFiltered = branchFiltered
    },
    ADD_REPLACED_FILTERED(state, replacedFiltered) {
      state.replacedFiltered = replacedFiltered
    },
    SET_TAGS(state, tags) {
      state.tags = tags
    },
    SET_LOGIN_SYNCED(state, loginSynced) {
      state.loginSynced = loginSynced
    },
    SET_USERDATA(state, { username, password, readerStart, setTime, offset }) {
      state.username = username
      state.password = password
      state.readerStart =
        readerStart === null ? readerStartDefault : readerStart
      if (offset) {
        state.offset = offset
      }
      state.setTime = setTime
      localStorage.setItem(
        'userData',
        JSON.stringify({
          username,
          password: btoa(password),
          readerStart,
          setTime,
          offset
        })
      )
    },
    SET_USER_REPLACEMENTS(state, replacements) {
      if (!replacements) {
        replacements = {}
      }
      state.replacements = replacements
    },
    INC_ACTIVE_CONNECTORS(state) {
      state.activeConnectors += 1
    },
    SET_LOADING(state, isLoading) {
      state.loading = isLoading
    },
    SET_FIREBASE_AUTH(state, fireBaseAuth) {
      state.fireBaseAuth = fireBaseAuth
    },
    SET_FIRESTORE(state, fireStore) {
      state.fireStore = fireStore
    },
    SET_REPLACEMENT(state, [replacedId, replacementId]) {
      state.replacements[replacedId] = replacementId
    },
    SET_UNSUB_USERDATA(state, unsubUserData) {
      state.unsubUserData = unsubUserData
    },
    SET_USER_BRANCH(state, branch) {
      state.branch = branch
    }
  },

  actions: {
    filterSite({ state, commit }) {
      const filtered = _.filter(state.complete, function (o) {
        return o.properties['SITE'] === state.blogName
      })

      const ordered = _.orderBy(filtered, ['timestamp'], 'desc')
      commit('ADD_SITE_FILTERED', ordered)
      document.title = state.blogName
    },
    async filterEpoch({ state, commit, dispatch }) {
      const nowUTC = moment.utc().unix() + state.offset * 3600
      const elapsed = nowUTC - state.readerStart

      const storyStart = moment.utc(process.env.VUE_APP_STORY_START).unix()
      const storyPoint = storyStart + elapsed
      this._vm.$log.debug(
        'storyPoint UTC:',
        moment.unix(storyPoint).utc().format()
      )

      const filteredEntries = filterDeep(
        JSON.parse(JSON.stringify(state.siteFiltered)),
        (post) => post.timestamp <= storyPoint
      )[0]
      commit('ADD_EPOCH_FILTERED', filteredEntries)
      await dispatch('subscribeUserData')
    },
    async publishBlog({ state, commit }) {
      const nowUTC = moment.utc().unix() + state.offset * 3600
      const filteredEntries = _.filter(
        JSON.parse(JSON.stringify(state.blogPosts)),
        (post) => post.timestamp <= nowUTC
      )
      const ordered = _.orderBy(filteredEntries, ['timestamp'], 'desc')
      commit('ADD_BLOG_PUBLISHED', ordered)
    },
    async subscribeUserData({ state, commit, getters, dispatch }) {
      if (getters.isLoggedIn) {
        const usersDataRef = collection(state.fireStore, 'usersData')
        const dok = doc(usersDataRef, getters.getFirebaseEmail(state.username))
        const docSnap = await getDoc(dok)
        if (docSnap.exists()) {
          const userData = docSnap.data()
          commit('SET_USER_REPLACEMENTS', userData.replacements)
          commit('SET_USER_BRANCH', userData.branch)
          dispatch('filterBranch')
        } else {
          // doc.data() will be undefined in this case
          this._vm.$log.error('No such document!')
        }
        const unsubUserData = onSnapshot(dok, (docSnap) => {
          const userData = docSnap.data()
          if (
            !_.isEqual(state.replacements, userData.replacements) ||
            state.branch !== userData.branch
          ) {
            commit('SET_USER_REPLACEMENTS', userData.replacements)
            commit('SET_USER_BRANCH', userData.branch)
            dispatch('filterBranch')
          }
        })
        commit('SET_UNSUB_USERDATA', unsubUserData)
      } else {
        dispatch('filterBranch')
      }
    },
    filterBranch({ state, commit, dispatch }) {
      const branchFiltered = filterDeep(
        JSON.parse(JSON.stringify(state.epochFiltered)),
        (post) => post.properties['BRANCH'].split(' ').includes(state.branch)
      )[0]
      commit('ADD_BRANCH_FILTERED', branchFiltered)
      dispatch('filterReplaced')
    },
    filterReplaced({ state, commit }) {
      const noReplacement = state.branchFiltered.filter((post) => {
        return post.properties['WARPTYPE'].toLowerCase() !== 'replacement'
      })
      const replaced = noReplacement.map(function (post) {
        const replacementId = state.replacements[post.properties['ID']]
        if (replacementId) {
          const replacement = state.epochFiltered.find(
            (entry) => entry.properties['ID'] === replacementId
          )
          if (replacement) {
            post = replacement
          }
        }
        return post
      })
      commit('ADD_REPLACED_FILTERED', replaced)

      let tags = {}
      for (const entry of replaced) {
        for (const tag of Object.keys(entry.tags)) {
          tags[tag] = tags[tag] + 1 || 1
        }
      }
      commit('SET_TAGS', tags)
    },
    async setFireBaseAuth({ commit }) {
      const fireBaseAuth = getAuth()
      await fireBaseAuth.setPersistence(browserLocalPersistence)
      commit('SET_FIREBASE_AUTH', fireBaseAuth)
    },
    async fetchReaderStart({ state, commit, getters }, { username, password }) {
      const usersDataRef = collection(state.fireStore, 'usersData')
      const dok = doc(usersDataRef, getters.getFirebaseEmail(username))
      const docSnap = await getDoc(dok)

      if (docSnap.exists()) {
        const userData = docSnap.data()
        commit('SET_USERDATA', {
          username,
          password,
          readerStart: moment
            .utc(userData.start)
            // .startOf('day')
            // .subtract(3, 'days')
            .unix(),
          setTime: moment().utc().unix(),
          offset: userData.offset
        })
      } else {
        // doc.data() will be undefined in this case
        this._vm.$log.error('No such document!')
      }
    },
    async login({ dispatch, getters, commit }, { username, password }) {
      commit('SET_LOADING', true)
      try {
        await dispatch('fetchReaderStart', { username, password })
        if (getters.isSafari) {
          await dispatch('propagateUserData')
        } else {
          await dispatch('filterEpoch')
        }
      } finally {
        commit('SET_LOADING', false)
      }
    },
    propagateUserData({ state, getters }) {
      const userData = window.btoa(
        JSON.stringify({
          username: state.username,
          password: btoa(state.password),
          readerStart: state.readerStart,
          setTime: state.setTime,
          offset: state.offset
        })
      )
      let connectorsArr = Object.keys(getters.getConnectors)
      connectorsArr.push(location.origin)
      const connectorsString = window.btoa(JSON.stringify(connectorsArr))
      location.assign(
        connectorsArr[0] +
          '?userData=' +
          userData +
          '&connectors=' +
          connectorsString
      )
    },
    async logout({ commit, dispatch, getters }) {
      commit('SET_LOADING', true)
      await dispatch('signOutFireBase')

      await commit('SET_USERDATA', {
        username: null,
        password: '',
        readerStart: null,
        offset: null,
        setTime: moment().utc().unix()
      })
      if (getters.isSafari) {
        await dispatch('propagateUserData')
      } else {
        location.href = '/'
      }
    },
    async unsubUserData({ state }) {
      if (state.unsubUserData) {
        await state.unsubUserData()
      }
    },
    async signOutFireBase({ state, dispatch }) {
      await dispatch('unsubUserData')
      await state.fireBaseAuth.signOut()
    },
    async connectorLoaded({ commit, getters, dispatch }) {
      commit('INC_ACTIVE_CONNECTORS')
      if (getters.areConnectorsLoaded) {
        await dispatch('syncConnectors')
        commit('SET_LOGIN_SYNCED', true)
        for (;;) {
          await new Promise((resolve) => setTimeout(resolve, 500))
          await dispatch('syncConnectors')
        }
      }
    },
    async syncConnectors({ state, commit, getters, dispatch }) {
      let userDatas = []
      for (const connName of Object.values(getters.getConnectors)) {
        const connHost = _.findKey(urlToName[process.env.VUE_APP_MODE], (o) => {
          return o === connName
        })
        try {
          const postResp = await postRobot.send(
            window.frames[connName],
            'getUserData',
            '',
            { domain: connHost, timeout: 1500 }
          )
          if (postResp.origin === connHost) {
            const userData = postResp.data
            if (userData) {
              let parsedUserData = JSON.parse(userData)
              parsedUserData['password'] = atob(parsedUserData['password'])
              userDatas.push(parsedUserData)
            }
          }
        } catch (err) {
          this._vm.$log.error(`Connector ${connName} error: ${err}`)
        }
      }

      const userDataLoc = JSON.parse(localStorage.getItem('userData'))
      if (userDataLoc) {
        userDataLoc['password'] = atob(userDataLoc['password'])
      }

      if (userDataLoc !== null) {
        userDatas.push(userDataLoc)
      }

      if (userDatas.length !== 0) {
        const mostRecent = _.sortBy(userDatas, ['setTime']).reverse()[0]
        if (state.setTime !== mostRecent.setTime) {
          if (mostRecent.username === null) {
            if (getters.isLoggedIn) {
              await dispatch('signOutFireBase')
              commit('SET_USERDATA', mostRecent)
              location.href = '/'
            } else {
              commit('SET_USERDATA', mostRecent)
            }
          } else {
            if (getters.isLoggedIn) {
              await dispatch('signOutFireBase')
              await signInWithEmailAndPassword(
                state.fireBaseAuth,
                getters.getFirebaseEmail(mostRecent.username),
                mostRecent.password
              )
              commit('SET_USERDATA', mostRecent)
              location.href = '/'
            } else {
              await dispatch('signOutFireBase')
              await signInWithEmailAndPassword(
                state.fireBaseAuth,
                getters.getFirebaseEmail(mostRecent.username),
                mostRecent.password
              )
              commit('SET_USERDATA', mostRecent)
            }
          }
          await dispatch('filterEpoch')
        }
      } else {
        await dispatch('filterEpoch')
      }
    },
    async replacePost({ state, commit, getters }, post) {
      if (
        state.replacements[post.properties['REPLACEDID']] !==
        post.properties['ID']
      ) {
        commit('SET_LOADING', true)
        const usersDataRef = collection(state.fireStore, 'usersData')
        const dok = doc(usersDataRef, getters.getFirebaseEmail(state.username))
        let updateReplacements = {}
        updateReplacements[`replacements.${post.properties['REPLACEDID']}`] =
          post.properties['ID']
        await updateDoc(dok, updateReplacements)
        commit('SET_REPLACEMENT', [
          post.properties['REPLACEDID'],
          post.properties['ID']
        ])
        commit('SET_LOADING', false)
      }
    },
    async switchBranch({ state, commit, getters }, switchBranchPost) {
      const possibleBranches = switchBranchPost.properties['BRANCH'].split(' ')
      for (let possibleBranch of possibleBranches) {
        if (
          possibleBranch.startsWith(state.branch) &&
          possibleBranch.length === state.branch.length + 1
        ) {
          commit('SET_LOADING', true)
          const usersDataRef = collection(state.fireStore, 'usersData')
          const dok = doc(
            usersDataRef,
            getters.getFirebaseEmail(state.username)
          )
          await updateDoc(dok, { branch: possibleBranch })
          commit('SET_LOADING', false)
          break
        }
      }
    }
  },

  getters: {
    getBranchEntryBySlug: (state) => (slug) => {
      // find all matching slug
      const matched = _.filter(state.branchFiltered, function (entry) {
        return entry.slug === slug
      })
      if (matched.length === 0) {
        return null
      } else if (matched.length === 1) {
        return matched[0]
      } else {
        // if many is found check if it's the original POST ie `WARPTYPE: Replaced`
        const replaced = matched.find(
          (entry) => entry.properties['WARPTYPE'].toLowerCase() === 'replaced'
        )
        if (replaced) {
          return replaced
        } else {
          // if many is found but no original post, meaning that many Replacements have same slug
          //-> find which replacement is actually used and return it
          const replacement = matched.find((entry) =>
            Object.values(state.replacements).includes(entry.properties['ID'])
          )
          return replacement
        }
      }
    },
    getBranchPostById: (state) => (id) => {
      // Get post by it's own id or by id of any related items
      return state.branchFiltered.find((entry) => {
        if (entry.properties['ID'] === id) {
          return true
        } else {
          return searchRelated(entry, id) !== null
        }
      })
    },
    getEpochEntryById: (state) => (id) => {
      // Get post or related by it's id
      const entry = searchRelated(
        { related: state.epochFiltered, properties: {} },
        id
      )
      return entry
    },
    getPostBySelfOrRelatedId: (state) => (id) => {
      // Get post by it's own id or by id of any related items
      return state.complete.find((entry) => {
        if (entry.properties['ID'] === id) {
          return true
        } else {
          return searchRelated(entry, id) !== null
        }
      })
    },
    getRCGBySelfOrRelatedId: (state) => (id) => {
      // Get post by it's own id or by id of any related items
      return state.rcg.find((entry) => {
        if (entry.properties['ID'] === id) {
          return true
        } else {
          return searchRelated(entry, id) !== null
        }
      })
    },
    getPostSlugById: (state, getters) => (id) => {
      const post = getters.getPostBySelfOrRelatedId(id)
      return post.slug
    },
    getRCGSlugById: (state, getters) => (id) => {
      const rcgSection = getters.getRCGBySelfOrRelatedId(id)
      return rcgSection.slug
    },
    getBlogDomainById: (state, getters) => (id) => {
      const post = getters.getPostBySelfOrRelatedId(id)
      if (!post) {
        return ''
      }
      const postBlogName = post.properties['SITE']
      return _.findKey(
        urlToName[process.env.VUE_APP_MODE],
        function (blogName) {
          return postBlogName === blogName
        }
      )
    },
    getRcgDomainById: (state, getters) => (id) => {
      const rcgSection = getters.getRCGBySelfOrRelatedId(id)
      if (!rcgSection) {
        return ''
      }
      return getters.getRCGLink()
    },
    getEntriesByTag: (state) => (tag) => {
      return state.replacedFiltered.filter((entry) =>
        Object.keys(entry.tags).includes(tag)
      )
    },
    postDisplayDate: () => (post) => {
      let timestamp = post.timestamp
      if (post.ftimestamp !== undefined) {
        timestamp = post.ftimestamp
      }

      // Had been used to display post date with timezone
      // let timezone = post.timezone
      // if (post.ftimezone !== undefined) {
      //   timezone = post.ftimezone
      // }

      return (
        moment
          .unix(timestamp)
          // .utc()
          // .utcOffset(timezone)
          .format('MM/DD/YYYY HH:mm')
      )
    },
    isLoggedIn: (state) => {
      return state.username !== null
    },
    getConnectors: (state, getters) => {
      let connectors = getters.getRestOfSuite
      return _.mapKeys(connectors, (v, k) => {
        return k + '/connector.html'
      })
    },
    getRestOfSuite: () => {
      return _.pickBy(urlToName[process.env.VUE_APP_MODE], (v, k) => {
        return k !== window.location.origin
      })
    },
    areConnectorsLoaded: (state, getters) => {
      return (
        state.activeConnectors === Object.keys(getters.getConnectors).length
      )
    },
    isProduction: () => {
      return process.env.VUE_APP_MODE === 'production'
    },
    chunkBody: () => (body) => {
      return body.split(/\[\[(.*?)\]\]/g)
    },
    trimmedBody: (state, getters) => (body) => {
      const chunkedBody = getters.chunkBody(body)
      let bodyLen = 0
      const bodyLenLim = 205
      let trimmedChunks = []
      for (let chunk of chunkedBody) {
        if (chunk.includes('][')) {
          bodyLen += getters.parseLink(chunk)[1].length
        } else {
          bodyLen += chunk.length
        }

        if (bodyLen > bodyLenLim) {
          if (chunk.includes('][')) {
            trimmedChunks.push(
              chunk.slice(
                0,
                chunk.indexOf('][') +
                  2 +
                  bodyLenLim -
                  bodyLen -
                  getters.parseLink(chunk)[1].length
              )
            )
          } else {
            trimmedChunks.push(
              chunk.slice(0, bodyLenLim - (bodyLen - chunk.length))
            )
          }
          trimmedChunks.push('...')
          break
        }
        trimmedChunks.push(chunk)
      }
      return trimmedChunks
    },
    parseLink: (state, getters) => (orgLink) => {
      const linkSplit = orgLink.split('][')
      const id = linkSplit[0].split(':')[1]
      const blogDomain = getters.getBlogDomainById(id)
      if (blogDomain !== '') {
        linkSplit[0] =
          blogDomain + '/#/post/' + getters.getPostSlugById(id) + '?id=' + id
      } else {
        const rcgDomain = getters.getRcgDomainById(id)
        if (rcgDomain !== '') {
          linkSplit[0] =
            rcgDomain + '/#/' + getters.getRCGSlugById(id) + '#' + id
        }
      }
      return linkSplit
    },
    parseImg: () => (orgImage) => {
      return orgImage.replace('../public', '')
    },
    isSafari: () => {
      return /.*Version.*Safari.*/.test(navigator.userAgent)
    },
    getFirebaseEmail: () => (username) => {
      return username + '@onehundred20user.com'
    },
    getRCGLink: () => {
      return rcgLink[process.env.VUE_APP_MODE]
    }
  },
  modules: {}
})

const searchRelated = function (entry, id) {
  if (entry.properties['ID'] === id) {
    return entry
  }

  if (entry.length !== 0) {
    for (let relatedNode of entry.related) {
      let found = searchRelated(relatedNode, id)
      if (found !== null) {
        return found
      }
    }
  }
  return null
}

const filterDeep = (entries, infiltrator) => {
  const filtered = _.filter(entries, infiltrator)
  let relatedCount = 0
  for (let filteredEntry of filtered) {
    if (filteredEntry.related.length > 0) {
      const [filteredRelated, nestedCount] = filterDeep(
        filteredEntry.related,
        infiltrator
      )
      relatedCount += nestedCount
      filteredEntry.relatedCount = nestedCount
      filteredEntry.related = filteredRelated
    } else {
      filteredEntry.relatedCount = 0
    }
  }
  relatedCount += filtered.length
  return [filtered, relatedCount]
}
