<template>
  <div>
    <article
      v-for="post in filteredToPage"
      :key="post.key"
      class="post-entry p-2 w-full mx-auto flex flex-wrap mb-2"
    >
      <router-link
        :to="{ name: 'post', params: { slug: post.slug } }"
        class="post-link"
        >{{ post.headline }}</router-link
      >
      <post-body-trimmed
        :body="post.body"
        class="post-body-trimmed"
        @readMore="$router.push({ name: 'post', params: { slug: post.slug } })"
      ></post-body-trimmed>
      <span class="ml-auto">
        <span :class="{ 'fake-date': post.ftimestamp !== undefined }">
          {{ postDisplayDate(post) }}
        </span>
        |
        <router-link
          :to="{ name: 'post', params: { slug: post.slug } }"
          class="comments-link"
          >Comments({{ post.relatedCount }})</router-link
        >
      </span>
    </article>
    <nav class="float-right">
      <template v-if="page !== 1">
        <router-link
          :to="{ name: 'posts', query: { page: page - 1 } }"
          rel="prev"
          >Prev Page</router-link
        >
      </template>
      <template v-if="page !== 1"> | </template>
      <template v-if="pageCount > 1">
        <span
          class="d-inline-block p-1"
          v-for="pageNum in pageCount"
          :key="pageNum"
        >
          <span v-if="page === pageNum">{{ page }}</span>
          <router-link
            v-else
            class="d-inline-block"
            :to="{ name: 'posts', query: { page: pageNum } }"
            >{{ pageNum }}</router-link
          >
        </span>
      </template>
      <template v-if="hasNextPage"> | </template>
      <template v-if="hasNextPage">
        <router-link
          :to="{ name: 'posts', query: { page: page + 1 } }"
          rel="next"
          >Next Page</router-link
        >
      </template>
    </nav>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PostBodyTrimmed from '@/components/PostBodyTrimmed'

export default {
  name: 'story-posts',
  props: {
    page: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(['replacedFiltered', 'postsPerPage']),
    ...mapGetters(['postDisplayDate', 'chunkBody', 'parseLink']),
    hasNextPage() {
      return this.replacedFiltered.length > this.page * this.postsPerPage
    },
    pageCount() {
      return Math.ceil(this.replacedFiltered.length / this.postsPerPage)
    },
    filteredToPage() {
      return this.replacedFiltered.slice(
        (this.page - 1) * this.postsPerPage,
        this.page * this.postsPerPage
      )
    }
  },
  components: {
    PostBodyTrimmed
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/posts';
</style>
