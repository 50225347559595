<template>
  <main class="flex flex-col flex-nowrap justify-center mx-auto main" id="app">
    <template v-if="loginSynced">
      <top-section
        class="flex w-full px-3 mx-auto items-baseline justify-center"
        v-show="!loading"
      />
      <navigation-section
        class="flex p-3 w-full mx-auto items-baseline justify-between"
        v-show="!loading"
      />
      <section class="flex px-3 w-full mx-auto relative" v-show="!loading">
        <aside
          class="flex flex-wrap w-2/12 mb-2 mr-auto text-center sidebar border border-black"
          v-if="blogName === 'HarMegiddo'"
        >
          <left-column></left-column>
        </aside>
        <router-view :key="$route.fullPath" />
        <aside
          class="flex flex-col w-2/12 mb-2 ml-auto text-center sidebar"
          v-if="blogName !== 'one20days'"
        >
          <ads-section class="flex mx-1 p-1 overflow-hidden w-full" />
          <tag-cloud />
          <related-links class="flex flex-col flex-wrap mx-1 p-1 w-full" />
        </aside>
      </section>
    </template>
    <footer
      class="connector"
      v-for="(connName, connHost) in getConnectors"
      :key="connHost"
    >
      <vue-friendly-iframe
        :src="connHost"
        :name="connName"
        @load="onLoad"
      ></vue-friendly-iframe>
    </footer>
  </main>
</template>

<script>
import NavigationSection from '@/components/NavigationSection.vue'
import AdsSection from '@/components/AdsSection.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import TagCloud from '@/components/TagCloud.vue'
import LeftColumn from '@/components/LeftColumn.vue'
import { mapGetters, mapState } from 'vuex'
import { routes } from '@/router'
import TopSection from '@/components/TopSection'

export default {
  name: 'app',
  components: {
    TopSection,
    NavigationSection,
    TagCloud,
    AdsSection,
    RelatedLinks,
    LeftColumn
  },
  computed: {
    ...mapGetters(['getConnectors']),
    ...mapState(['loginSynced', 'blogName', 'loading'])
  },
  watch: {
    loginSynced(newValue) {
      if (newValue === true) {
        for (let route of routes) {
          this.$router.addRoute(route)
        }
      }
    }
  },
  methods: {
    async onLoad() {
      await this.$store.dispatch('connectorLoaded')
    }
  },
  created() {
    this.$log.info('mode:', process.env.VUE_APP_MODE)
    document.querySelector('body').id = this.blogName
  }
}
</script>

<style lang="scss">
@import './assets/scss/global';
</style>
