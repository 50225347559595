const urlToName = {
  development: {
    'http://localhost:8080': 'FreedomMarch',
    'http://localhost:8081': 'HarMegiddo',
    'http://localhost:8082': 'WordMadMan',
    'http://localhost:8083': 'one20days'
  },
  production: {
    'https://freedomonthemarch.org': 'FreedomMarch',
    'https://harmegiddo.org': 'HarMegiddo',
    'https://wordofamadman.org': 'WordMadMan',
    'https://120daysofsaddam.net': 'one20days'
  },
  staging: {
    'https://staging.freedomonthemarch.org': 'FreedomMarch',
    'https://staging.harmegiddo.org': 'HarMegiddo',
    'https://staging.wordofamadman.org': 'WordMadMan',
    'https://staging.120daysofsaddam.net': 'one20days'
  }
}

const rcgLink = {
  development: 'http://localhost:9000',
  production: 'https://regimechangegroup.com',
  staging: 'https://staging.regimechangegroup.com'
}

export { urlToName, rcgLink }

// chatGPT input:
// Alright, let's dive into the `const.js` file. This file seems to contain configurations for various environments (development, production, and staging). The purpose is presumably to map URLs to specific names and to set up the links for each environment.

// ### Breakdown:

// #### `urlToName` Object
// This object maps URLs to more friendly, shorthand names for different environments. These are most likely your application endpoints, and you're giving each one a more human-readable name.
// - `development`: Mapping localhost URLs
// - `production`: Mapping production URLs
// - `staging`: Mapping staging URLs

// #### `rcgLink` Object
// Just like the `urlToName` object, this one seems to map the root URLs for "Regime Change Group" (judging by the domain name) for different environments.

// ### Points to Consider

// 1. **Environment Variables**: These constants seem like the kind of stuff you might want to put in environment variables if they ever change based on deployment or local development setup.

// 2. **Dynamic URL Handling**: If you add more URLs later on, you'd need to update this file and redeploy. Consider if there's a more dynamic way to handle this.

// 3. **Documentation**: It might be beneficial to add some comments describing what each object is used for, particularly if other team members or your future self have to understand it quickly.

// 4. **File Name**: `const.js` doesn't tell me a lot about what constants are inside. If you start adding more constants that serve other purposes, you might want to consider splitting this into multiple files or renaming it to something more descriptive.

// 5. **Imports**: It's good that you're exporting these objects so they can be imported and used in other parts of the application where necessary. Just make sure to handle any errors or issues that might arise if these constants ever change.

// That's about it. It's a straightforward file but, as always, the devil's in the details! Anything else you'd like to discuss?
